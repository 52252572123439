import React, { useState, useEffect } from 'react';

import { Table, Button, Modal, Tooltip } from "antd";

import { PlusOutlined, EditOutlined, DeleteOutlined, CloseOutlined, CheckOutlined } from "@ant-design/icons";

import TableButtons from '../../components/TableButtons';

import DNSDrawer from './DNSDrawer';

import REQUESTS from '../../api/requests';

import getMyDate from '../../components/getMyDate';

import FreeDNDStatistic from './FreeDNDStatistic';
import getColumnSearchProps from '../../components/getColumnSearchProps';
import styles from "./index.module.scss";
import { useSelector } from 'react-redux';
import { selectwidgets } from '../../features/widgets/selector';

export default function FreeDNS () {
    const [data, setData] = useState([]);

    const [total, setTotal] = useState();

    const [limit, setLimit] = useState(10);

    const [currentPage, setCurrentPage] = useState(1);

    const [loading, setLoading] = useState(false);

    const [open, setOpen] = useState(false);

    const [current, setCurrent] = useState(null);

    const [search, setSearch] = useState({});

    const [statsticDrawer, setStatsticDrawer] = useState(false);

    const widget = useSelector(selectwidgets)

    console.log(widget)

    const columns = widget && widget.free_dns_activation?.enabled == "true" ? [
        {
            title: "#",
            dataIndex: "id",
            align: "center",
            render: (text, record, index) => {
                if (currentPage === 1) {
                    return index + 1;
                } else {
                    return limit * (currentPage - 1) + index + 1;
                }
            },
        },
        {
            title: "DNS",
            dataIndex: "dns",
            key: "dns",
            align: "center",
            ...getColumnSearchProps(),
            render: (text) => {
                return <Tooltip placement="topLeft" title={text}>
                    <div style={{
                        width: 200,
                        whiteSpace: 'nowrap',
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}>{text}</div>
                </Tooltip>
            }
        },
        {
            title: "Activation count",
            dataIndex: "activation_count",
            key: "activation_count",
            align: "center",
        },
        {
            title: "Max Activation count",
            dataIndex: "max_activation_coun",
            key: "max_activation_coun",
            align: "center",
            render: (text, record, index) => {
                if (record?.max_activation_count === 0) {
                    return "Unlimited"
                } else {
                    return record?.max_activation_count
                }
            }
        },
        {
            title: "Checked playlist",
            dataIndex: "check_playlist",
            key: "check_playlist",
            align: "center",
            render: (text, record, index) => {
                if (record?.check_playlist) {
                    return <CheckOutlined style={{ color: "green" }} />
                } else {
                    return <CloseOutlined style={{ color: "red" }} />
                }
            }
        },
        {
            title: "Activation type",
            dataIndex: "activation_type",
            key: "activation_type",
            align: "center",
            render: (text, record, index) => text?.replace("_", " ")
        },

        {
            title: 'Remark',
            dataIndex: 'remark',
            key: 'remark',
            align: 'center',
            width: 200,
            ellipsis: {
                showTitle: false,
            },
            render: (text, record) => (
                <Tooltip placement="topLeft" title={record?.remark}>
                    <div style={{
                        width: 200,
                        whiteSpace: 'nowrap',
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}>{record?.remark}</div>
                </Tooltip>
            ),
        },
        {
            title: "Created Date",
            dataIndex: "createdAt",
            key: "createdAt",
            align: "center",
            render: (text, record, index) => record && record.createdAt ? getMyDate(record.createdAt) : "-"
        },
        {
            title: "",
            dataIndex: "action",
            width: "60px",
            align: "center",
            render: (text, record, index) => !record.multy_player_id && (
                <div onClick={(e) => e.stopPropagation()}>
                    <TableButtons
                        handleMenuClick={(e) => {
                            handleMenuClick(e, record)
                        }}
                        buttons={[
                            { key: "edit", text: "edit", icon: <EditOutlined /> },
                            {
                                key: "delete",
                                text: "delete",
                                icon: <DeleteOutlined />,
                            },
                        ]}
                    />
                </div>
            ),
        },
    ] : [
        {
            title: "#",
            dataIndex: "id",
            align: "center",
            width: 50,
            render: (text, record, index) => {
                if (currentPage === 1) {
                    return index + 1;
                } else {
                    return limit * (currentPage - 1) + index + 1;
                }
            },
        },
        {
            title: "DNS",
            dataIndex: "dns",
            key: "dns",
            align: "center",
            width: 400,
            ...getColumnSearchProps(),
            render: (text) => {
                return <Tooltip placement="topLeft" title={text}>
                    <div style={{
                        whiteSpace: 'nowrap',
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: 400,
                    }}>{text}</div>
                </Tooltip>
            }
        },
        {
            title: 'Remark',
            dataIndex: 'remark',
            key: 'remark',
            align: 'center',
            width: 400,
            render: (text, record) => (
                <Tooltip placement="topLeft" title={record?.remark}>
                    <div style={{
                        width: 400,
                        whiteSpace: 'nowrap',
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}>{record?.remark}</div>
                </Tooltip>
            ),
        },
        {
            title: "Created Date",
            dataIndex: "createdAt",
            key: "createdAt",
            align: "center",
            render: (text, record, index) => record && record.createdAt ? getMyDate(record.createdAt) : "-"
        },
        {
            title: "",
            dataIndex: "action",
            width: "60px",
            align: "center",
            render: (text, record, index) => !record.multy_player_id && (
                <div onClick={(e) => e.stopPropagation()}>
                    <TableButtons
                        handleMenuClick={(e) => {
                            handleMenuClick(e, record)
                        }}
                        buttons={[
                            { key: "edit", text: "edit", icon: <EditOutlined /> },
                            {
                                key: "delete",
                                text: "delete",
                                icon: <DeleteOutlined />,
                            },
                        ]}
                    />
                </div>
            ),
        },
    ];

    const getData = () => {

        setLoading(true);

        const query = {
            page: currentPage,
            limit,
            pagination: 1,
        }

        if (search && search.dns && search.dns[0]) {
            query["search"] = {}
            query["search"]["dns"] = search.dns?.[0];
            query["search"] = JSON.stringify(query["search"]);
        }

        function callback (data) {
            setLoading(false);

            setTotal(data.count);

            setLimit(data.limit);

            setData(data.rows);
        }

        function errorCallback (error) {
            setLoading(false);
        }

        REQUESTS.FREE_DNS.GET(query, callback, errorCallback);
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setCurrentPage(pagination.current);
        setLimit(pagination.pageSize);
        setSearch(filters);
    };

    const deleteDns = (id) => {
        REQUESTS.FREE_DNS.DELETE({ id }, () => {
            getData()
        })
    }

    const handleMenuClick = (e, item) => {
        switch (e.key) {
            case "delete":
                Modal.confirm({
                    title: "Are you sure you want to delete?",
                    okText: "DELETE",
                    okButtonProps: {
                        danger: true,
                        type: "primary",
                        style: {
                            backgroundColor: "#ff4d4f",
                        }
                    },
                    onOk () {
                        deleteDns(item.id);
                    },
                });

                break;
            case "edit":

                setOpen(true);

                setCurrent(item);

                break;

            default:
                break;
        }
    };

    useEffect(() => {
        let timout = setTimeout(() => {
            getData();
        }, 500);
        return () => {
            clearTimeout(timout);
        };
    }, [currentPage, limit, search]);

    return (
        <div>
            <div className="settings-free-dns">
                <h3>Free DNS</h3>

                <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => setOpen(true)}
                />
            </div>

            <Table
                loading={loading}
                rowKey="id"
                columns={columns}
                dataSource={data}
                onChange={handleTableChange}
                pagination={{
                    position: ["bottomCenter"],
                    current: currentPage,
                    total: total,
                    pageSize: limit,
                    showSizeChanger: true,
                }}
                scroll={{ x: "max-content" }}
                size="small"
                className={styles['table-row']}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (event) => {
                            setStatsticDrawer(true);
                            setCurrent(record);
                        },
                    };
                }}
            />
            <DNSDrawer
                open={open}
                onClose={() => {
                    setOpen(false);
                    setCurrent(null);
                }}
                getData={getData}
                current={current}
            />

            <FreeDNDStatistic
                open={statsticDrawer}
                onClose={() => {
                    setStatsticDrawer(false);
                    setCurrent(null);
                }}
                current={current}
            />
        </div>
    )
}
